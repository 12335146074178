import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;

	.checkbox-wrapper {
		position: relative;
		text-align: left;
		cursor: pointer;
		display: inline-block;

		.checkbox-trigger {
			width: 20px;
			height: 20px;
			background-color: #ffffff;
			display: inline-block;
			cursor: pointer;
			position: relative;
			border: 1px solid #666666;
			border-radius: 0.2em;
		}

		.checkbox {
			position: absolute;
			z-index: -1;
			width: 1px;
			height: 1px;
			margin: 0;
			opacity: 0.01;

			&:checked + .checkbox-trigger,
			&[value='true'] + .checkbox-trigger {
				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					background: black;
					inset: 5px;
					width: 50%;
					height: 50%;
				}
			}
		}
	}

	.label {
		display: inline-block;
		padding-left: 0.9em;
		text-align: left;
		line-height: 120%;
		font-size: 0.8em;
		color: #121212;
		max-width: 90%;
		margin-top: -0.2em;

		a {
			text-decoration: underline;
			color: #ffffff;
		}

		.expand-trigger {
			cursor: pointer;
			margin-left: 0.5em;
			text-decoration: underline;
		}

		&.clicked {
            cursor: pointer;
        }
	}

	.layout-forms-input-wrapper {
		.internal-wrapper {
			border: none;
			border-radius: 0;

			.input-body {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
			}
		}
	}

	.error-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-bottom: 0.5em;

		.error-message {
			font-size: 0.8em;
			color: #ff0000;
		}
	}

	&.disabled {
		opacity: 0.5;
	}

	&.bold {
		.label {
			font-weight: 700;
		}
	}

	&.style-secondary {
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							border: 1px solid #c1c1c1;
						}

						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								&:after {
									background: transparent;
									background-image: url(/images/cart/check-gold.svg);
									background-repeat: no-repeat;
								}
							}
						}
					}
				}
			}
		}
	}

	&.style-green {
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							border: 1px solid ${vars.colorGreenDarker};
							width: 23px;
							height: 23px;
						}

						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								width: 23px;
								height: 23px;

								&:after {
									background: transparent;
									background-image: url(/images/cart/check-green.svg);
									background-repeat: no-repeat;
									background-size: 15px 15px;
									width: 100%;
									height: 100%;
									inset: 3px;
								}
							}
						}
					}
				}
			}
		}
	}

	&.style-required {
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							border: 2px solid #eb8817;
						}
					}
				}
			}
		}
	}

	&.style-circle {
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							border: 1px solid #262626;
							border-radius: 50%;
						}

						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								&:after {
									background-color: #ba9449;
									border-radius: 50%;
									background-repeat: no-repeat;
									background-size: cover;
									top: 50%;
									left: 50%;
									width: 0.7em;
									height: 0.7em;
									transform: translate(-50%, -50%);
								}
							}
						}
					}
				}
			}
		}
	}

	&.style-tertiary {
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							border: 1px solid #b9944a;
						}

						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								&:after {
									background: transparent;
									background-image: url(/theme/images/tick.svg);
									background-repeat: no-repeat;
									width: 2em;
									height: 2em;
									inset: -5px 0 0 0;
								}
							}
						}
					}
				}
			}
		}
	}

	&.style-white {
		.label {
			color: ${vars.colorWhite};
		}

		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							border: 1px solid ${vars.colorWhite};
							background: transparent;
						}

						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								&:after {
									background: transparent;
									background-image: url(/images/cart/check-white.svg);
									background-repeat: no-repeat;
								}
							}
						}
					}
				}
			}
		}
	}

	&.style-black {
		.input-body {
			color: #646060;
		}
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							background-color: transparent;
						}

						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								&:after {
									background: transparent;
									background-image: url(/images/cart/check-black.svg);
									background-repeat: no-repeat;
								}
							}
						}
					}
				}
			}
		}
	}

	&.style-bold-green {
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;
					.checkbox-wrapper {
						.checkbox-trigger {
							border: 2px solid #809366;
							border-radius: 5px;
						}
						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								&:after {
									inset: 4px;
									background: transparent;
									background-image: url(/images/cart/check-green.svg);
									background-repeat: no-repeat;
									transform: scale(1.4);
								}
							}
						}
					}

					.label {
						color: #809366;
						font-size: 0.88em;
						font-weight: 600;
						margin: 0 0 0.3em 0;
					}
				}
			}
		}
	}

	&.style-bold-green {
		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					display: flex;
					align-items: center;

					.checkbox-wrapper {
						.checkbox-trigger {
							border: 2px solid #809366;
							border-radius: 5px;
						}

						.checkbox {
							&:checked + .checkbox-trigger,
							&[value='true'] + .checkbox-trigger {
								&:after {
									inset: 4px;
									background: transparent;
									background-image: url(/images/cart/check-green.svg);
									background-repeat: no-repeat;
									transform: scale(1.4);
								}
							}
						}
					}

					.label {
						color: #809366;
						font-size: 0.88em;
						font-weight: 600;
						margin: 0 0 0.3em 0;
					}
				}
			}
		}
	}
`;
